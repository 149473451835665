import { makeStyles } from '@mui/styles';
import { Box, useTheme, useMediaQuery } from '@mui/material';

import Callback from './Callback';
import FederatedSignIn from './FederatedSignIn';
import FederatedSignOut from './FederatedSignOut';
import LoginForm from './Login/LoginForm';
import ForgotPasswordForm from './ForgotPassword/ForgotPasswordForm';
import ActivationForm from './Activation/ActivationForm';

export enum Mode {
  Login = 'login',
  ForgotPassword = 'forgot-password',
  Activation = 'activation',
  FederatedSignIn = 'sso-login',
  FederatedSignOut = 'signout',
  Callback = 'callback',
}

const FORM_MAPPER = {
  [Mode.Login]: LoginForm,
  [Mode.ForgotPassword]: ForgotPasswordForm,
  [Mode.Activation]: ActivationForm,
  [Mode.FederatedSignIn]: FederatedSignIn,
  [Mode.FederatedSignOut]: FederatedSignOut,
  [Mode.Callback]: Callback,
};

type Props = {
  mode:
    | Mode.Login
    | Mode.ForgotPassword
    | Mode.Activation
    | Mode.FederatedSignIn
    | Mode.FederatedSignOut
    | Mode.Callback;
};

const useStyles = makeStyles((theme) => ({
  login: {
    background: theme.palette.grey[200],
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
}));

const CognitoLogin = ({ mode }: Props) => {
  const Form = FORM_MAPPER[mode];
  const theme = useTheme();
  const classes = useStyles();

  const onlySmallAndMedium = useMediaQuery(theme.breakpoints.between('xs', 1540));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={classes.login}>
      {Boolean(Form) ? (
        <Box mt={onlySmallAndMedium ? 4 : 10} mb={6}>
          {/* @ts-ignore */}
          <Form />
        </Box>
      ) : (
        `Oops, cannot load form, unsupported mode ${mode} `
      )}

      {!isSmall && (
        <Box position="absolute" bottom="20px" color={theme.palette.grey[800]} style={{ opacity: 0.75 }}>
          Copyright © UptimeHealth, Inc {new Date().getFullYear()}
        </Box>
      )}
    </Box>
  );
};

export default CognitoLogin;
