import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Button, Typography, CircularProgress, Link, Grid, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { clearExternalState, clearJwtState } from '@uptime/shared/utils/general';
import { setLastLogin } from '@uptime/shared/graphql/users';

import { userClient } from 'apolloClientConfiguration';

import { useOnMount, useOnUpdate } from 'libs/hooks';
import { useCognito, IDL_COGNITO_MESSAGE_VALUE } from 'libs/providers/CognitoProvider';
import Input from 'libs/react-hook-form/Input';
import * as BaseLogin from 'libs/components/BaseLoginForm';

import LoginSchema from './Login.schema';

const MESSAGE_MAPPER = {
  [IDL_COGNITO_MESSAGE_VALUE.SESSION]: 'Your session has timed out. Please sign in again.',
  [IDL_COGNITO_MESSAGE_VALUE.LOGOUT]: 'You have successfully signed out.',
  [IDL_COGNITO_MESSAGE_VALUE.HIDE]: '',
};

const LoginForm = () => {
  const history = useHistory();
  const { signIn, isAuthorized, getShowIdlMessage, getLastLoginPath, getUser } = useCognito();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const [lastLogin] = useMutation(setLastLogin, {
    client: userClient,
    onCompleted() {
      window.location.href = getLastLoginPath();
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = useForm({
    defaultValues: {
      username: null,
      password: null,
    },
    resolver: yupResolver(LoginSchema),
  });

  const handleForgotPassword = useCallback(() => {
    const username = getValues().username;

    if (!username) {
      return history.push('/app/forgot-password');
    }

    return history.push(`/app/forgot-password?username=${encodeURIComponent(username)}`);
  }, []);

  const handleHostedUISignIn = useCallback(() => history.push('/app/sso-login'), []);

  const handleSignIn = useCallback(async ({ username, password }) => {
    setError('');
    setMessage('');

    try {
      clearJwtState();

      await signIn(username.trim(), password.trim());

      clearExternalState();
    } catch (e) {
      // @ts-ignore
      setError(e.message);
    }
  }, []);

  useOnUpdate(() => {
    if (!isAuthorized) return;

    const user = getUser();

    lastLogin({ variables: { userId: Number(user?.userId) } });
  }, [isAuthorized]);

  useOnMount(() => setMessage(MESSAGE_MAPPER[getShowIdlMessage() || IDL_COGNITO_MESSAGE_VALUE.HIDE]));

  return (
    <BaseLogin.Form onSubmit={handleSubmit(handleSignIn)}>
      <BaseLogin.Title>Sign In</BaseLogin.Title>

      <Box component={Grid} container justifyContent="center" p={5}>
        <BaseLogin.Info>{message}</BaseLogin.Info>

        <BaseLogin.Error>{error}</BaseLogin.Error>

        <BaseLogin.Input testid="username">
          <Input name="username" label="Username" fullWidth control={control} />
        </BaseLogin.Input>

        <BaseLogin.Input testid="password">
          <Input name="password" label="Password" type="password" fullWidth control={control} />
        </BaseLogin.Input>

        <BaseLogin.Button testid="submitButton">
          <Button disabled={isSubmitting || isAuthorized} type="submit" variant="contained" fullWidth>
            {isSubmitting && (
              <Box ml={-3} mr={1.5} mt={0.75}>
                <CircularProgress size={12} />
              </Box>
            )}

            <Typography color="inherit">Sign In</Typography>
          </Button>
        </BaseLogin.Button>

        <Stack spacing={3} mt={3} width="100%">
          <BaseLogin.Button>
            <Link
              component="button"
              underline="none"
              disabled={isSubmitting || isAuthorized}
              onClick={handleForgotPassword}
              data-testid="forgotBtn"
              variant="body2"
            >
              Forgot password?
            </Link>
          </BaseLogin.Button>

          <BaseLogin.Button>
            <Link
              component="button"
              underline="none"
              disabled={isSubmitting || isAuthorized}
              onClick={handleHostedUISignIn}
              data-testid="multiAppSsoBtn"
              variant="body2"
            >
              Sign In with Single Sign-on (SSO)
            </Link>
          </BaseLogin.Button>
        </Stack>
      </Box>
    </BaseLogin.Form>
  );
};

export default LoginForm;
