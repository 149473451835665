import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useOnMount } from 'libs/hooks';
import { useCognito } from 'libs/providers/CognitoProvider';

const FederatedSignOut = () => {
  const theme = useTheme();
  const { federatedSignOut } = useCognito();

  useOnMount(() => {
    setTimeout(federatedSignOut, 1000);
  });

  return (
    <Box
      width="100%"
      height="90vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography component={Box} my={2} color={theme.palette.grey[900]}>
        Signing out
      </Typography>
      {/* @ts-ignore */}
      <CircularProgress color="grey" size={50} thickness={1} />
    </Box>
  );
};

export default FederatedSignOut;
