import * as yup from 'yup';
import { COGNITO_PASSWORD_REGEXP } from 'libs/services/cognito';

export default (hasRequestedCode: boolean) =>
  yup.object({
    username: yup.string().nullable().required().label('Email or Username').max(255),

    ...(hasRequestedCode && {
      code: yup.string().nullable().required('Please enter a code').label('Verification code').max(255),
      password: yup
        .string()
        .nullable()
        .required()
        .label('New password')
        .max(255)
        .matches(COGNITO_PASSWORD_REGEXP, 'Password does not meet requirements'),
    }),
  });
