import { useCallback, useMemo, useState } from 'react';
import { Button, Typography, Box, CircularProgress, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from 'libs/react-hook-form/Input';
import { useCognito } from 'libs/providers/CognitoProvider';
import * as BaseLogin from 'libs/components/BaseLoginForm';

import getForgotPasswordSchema from './ForgotPassword.schema';

enum FormStatus {
  Initialized = 'Initialized',
  Requested = 'Requested',
  Changed = 'Changed',
}

const ForgotPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const { forgotPassword, confirmPassword } = useCognito();
  const [status, setFormStatus] = useState(FormStatus.Initialized);
  const [error, setError] = useState('');

  const username = new URLSearchParams(location.search).get('username');

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      username: username || null,
      code: null,
      password: null,
    },
    mode: 'onChange',
    resolver: yupResolver(getForgotPasswordSchema(status === FormStatus.Requested)),
  });

  const handleBackToSignIn = useCallback(() => history.push('/app/login'), []);

  const handleForgotPassword = useCallback(async ({ username }) => {
    setError('');

    try {
      await forgotPassword(username.trim());
      setFormStatus(FormStatus.Requested);
    } catch (e) {
      // @ts-ignore
      setError(e.message);
    }
  }, []);

  const handleConfirmPassword = useCallback(async ({ username, code, password }) => {
    setError('');

    try {
      await confirmPassword(username.trim(), code.trim(), password.trim());
      setFormStatus(FormStatus.Changed);
    } catch (e) {
      // @ts-ignore
      setError(e.message);
    }
  }, []);

  const title = useMemo(
    () =>
      ({
        [FormStatus.Initialized]: 'Forgot Password',
        [FormStatus.Requested]: 'Reset Password',
        [FormStatus.Changed]: 'Password change completed!',
      })[status],
    [status]
  );

  return (
    <BaseLogin.Form
      onSubmit={handleSubmit(status === FormStatus.Requested ? handleConfirmPassword : handleForgotPassword)}
    >
      <BaseLogin.Title>{title}</BaseLogin.Title>

      <Box component={Grid} container justifyContent="center" p={5}>
        <BaseLogin.Error>{error}</BaseLogin.Error>

        {status === FormStatus.Requested && (
          <Box
            mb={2}
            component={Grid}
            item
            xs={12}
            textAlign="left"
            fontSize="0.9rem"
            data-testid="loginRequirements"
          >
            <strong>Password requirements:</strong> At least 8 characters, a lowercase letter, an uppercase
            letter, a number.
          </Box>
        )}

        {status === FormStatus.Changed && (
          <Box
            mb={2}
            component={Grid}
            item
            xs={12}
            textAlign="center"
            fontSize="0.9rem"
            data-testid="loginSuccessMsg"
          >
            Your password has been changed successfully!
          </Box>
        )}

        {status === FormStatus.Changed && (
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <CheckCircleOutlineIcon color="primary" fontSize="large" />
          </Box>
        )}

        {status !== FormStatus.Changed && (
          <>
            {status === FormStatus.Initialized && (
              <BaseLogin.Input testid="username">
                <Input name="username" label="Username" autoComplete="off" fullWidth control={control} />
              </BaseLogin.Input>
            )}

            {status === FormStatus.Requested && (
              <>
                <BaseLogin.Input testid="verificationCode">
                  <Input
                    name="code"
                    label="Verification code"
                    autoComplete="one-time-code"
                    fullWidth
                    control={control}
                  />
                </BaseLogin.Input>

                <BaseLogin.Input testid="password">
                  <Input
                    type="password"
                    name="password"
                    label="New password"
                    autoComplete="new-password"
                    fullWidth
                    control={control}
                  />
                </BaseLogin.Input>
              </>
            )}

            <BaseLogin.Button testid="submitButton">
              <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
                {isSubmitting && (
                  <Box ml={-3} mr={1.5} mt={0.75}>
                    <CircularProgress size={12} />
                  </Box>
                )}

                <Typography color="inherit">
                  {status === FormStatus.Requested ? 'Confirm password' : 'Send Verification Code'}
                </Typography>
              </Button>
            </BaseLogin.Button>
          </>
        )}

        <BaseLogin.Button testid="backBtn">
          <Button fullWidth onClick={handleBackToSignIn} disabled={isSubmitting}>
            <Typography color="inherit">Back to Sign In</Typography>
          </Button>
        </BaseLogin.Button>
      </Box>
    </BaseLogin.Form>
  );
};

export default ForgotPassword;
