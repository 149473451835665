import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useOnMount } from 'libs/hooks';
import { useCognito } from 'libs/providers/CognitoProvider';

const FederatedSignIn = () => {
  const theme = useTheme();
  const { federatedSingIn } = useCognito();

  useOnMount(federatedSingIn);

  return (
    <Box
      width="100%"
      height="90vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography component={Box} my={2} color={theme.palette.grey[900]}>
        Redirecting
      </Typography>
      {/* @ts-ignore */}
      <CircularProgress color="grey" size={50} thickness={1} />
    </Box>
  );
};

export default FederatedSignIn;
