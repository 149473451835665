import React from 'react';
import { useMutation } from '@apollo/client';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

import { setLastLogin } from '@uptime/shared/graphql/users';
import { userClient } from 'apolloClientConfiguration';

import { useOnMount, useOnUpdate } from 'libs/hooks';
import { useCognito } from 'libs/providers/CognitoProvider';

const Callback = () => {
  const theme = useTheme();
  const { getFederatedUserSession, isAuthorized, getLastLoginPath, getUser } = useCognito();

  const [lastLogin] = useMutation(setLastLogin, {
    client: userClient,
    onCompleted() {
      window.location.href = getLastLoginPath();
    },
  });

  useOnMount(getFederatedUserSession);

  useOnUpdate(() => {
    if (!isAuthorized) return;

    const user = getUser();

    lastLogin({ variables: { userId: Number(user?.userId) } });
  }, [isAuthorized]);

  return (
    <Box
      width="100%"
      height="90vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography component={Box} my={2} color={theme.palette.grey[900]}>
        Signing in
      </Typography>
      {/* @ts-ignore */}
      <CircularProgress color="grey" size={50} thickness={1} />
    </Box>
  );
};

export default Callback;
