import qs from 'query-string';
import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { Box, Grid, Button, Typography, useTheme, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { setLastLogin } from '@uptime/shared/graphql/users';
import { userClient } from 'apolloClientConfiguration';
import { clearExternalState, clearJwtState } from '@uptime/shared/utils/general';

import { useOnMount, useOnUpdate } from 'libs/hooks';
import { useCognito } from 'libs/providers/CognitoProvider';
import Input from 'libs/react-hook-form/Input';

import * as BaseLogin from 'libs/components/BaseLoginForm';
import ShowPassword from 'libs/components/ShowPassword';

import Activation from './Activation.schema';

const ActivationForm = () => {
  const { resetPassword, isAuthorized, getUser } = useCognito();
  const location = useLocation();
  const theme = useTheme();
  const [error, setError] = useState('');

  const [lastLogin] = useMutation(setLastLogin, {
    client: userClient,
    onCompleted() {
      window.location.href = '/app';
    },
  });

  const { code } = qs.parse(location.search) as { code: string };

  const [visibility, setVisibility] = useState({
    password: false,
    confirm: false,
  });

  const handleVisibility = useCallback(
    (name) => () =>
      setVisibility((state) => ({
        ...state,
        [name]: !state[name],
      })),
    []
  );

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      username: null,
      password: null,
      confirm: null,
    },
    mode: 'onChange',
    resolver: yupResolver(Activation),
  });

  const [confirm, password] = watch(['confirm', 'password']);

  useOnUpdate(() => {
    trigger('confirm');
    trigger('password');
  }, [confirm, password]);

  const handleConfirm = useCallback(async ({ password, username }) => {
    setError('');

    try {
      clearJwtState();

      await resetPassword(username.trim(), code.trim(), password.trim());

      clearExternalState();
    } catch (e) {
      // @ts-ignore
      setError(e.message || 'Oops, something went wrong');
    }
  }, []);

  useOnMount(() => {
    if (!code) {
      setError('Invalid activation url');
    }
  });

  useOnUpdate(() => {
    if (!isAuthorized) return;

    const user = getUser();

    lastLogin({ variables: { userId: Number(user?.userId) } });
  }, [isAuthorized]);

  return (
    <BaseLogin.Form onSubmit={handleSubmit(handleConfirm)}>
      <BaseLogin.Title>Create Account</BaseLogin.Title>

      <Box component={Grid} container justifyContent="center" p={5}>
        <Box textAlign="center" color={theme.palette.secondary.dark}>
          <Typography variant="h3">
            <strong>Welcome to Uptime Controller!</strong>
          </Typography>
        </Box>

        <Box mt={2} component={Grid} item xs={12} textAlign="left">
          <Typography color={theme.palette.secondary.dark}>Please create your account</Typography>
        </Box>

        <Box my={2} component={Grid} item xs={12} textAlign="left" fontSize="0.9rem">
          <strong>Password requirements:</strong> At least 8 characters, a lowercase letter, an uppercase
          letter, a number.
        </Box>

        <BaseLogin.Error>{error}</BaseLogin.Error>

        <BaseLogin.Input testid="username">
          <Input name="username" label="Your username" fullWidth autoComplete="off" control={control} />
        </BaseLogin.Input>

        <BaseLogin.Input testid="password">
          <Input
            name="password"
            type={visibility.password ? 'text' : 'password'}
            label="Enter new password"
            fullWidth
            autoComplete="new-password"
            control={control}
            endAdornment={
              <ShowPassword visible={visibility.password} onShow={handleVisibility('password')} />
            }
          />
        </BaseLogin.Input>

        <BaseLogin.Input testid="confirmPassword">
          <Input
            name="confirm"
            type={visibility.confirm ? 'text' : 'password'}
            label="Confirm new password"
            fullWidth
            autoComplete="new-password"
            control={control}
            endAdornment={<ShowPassword visible={visibility.confirm} onShow={handleVisibility('confirm')} />}
          />
        </BaseLogin.Input>

        <BaseLogin.Button testid="submitButton">
          <Button type="submit" variant="contained" fullWidth disabled={isSubmitting || !code}>
            {isSubmitting && (
              <Box ml={-3} mr={1.5} mt={0.75}>
                <CircularProgress size={12} />
              </Box>
            )}

            <Typography>Create My Account</Typography>
          </Button>
        </BaseLogin.Button>
      </Box>
    </BaseLogin.Form>
  );
};

export default ActivationForm;
